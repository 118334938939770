var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("PatientInfoLayout", [
    _c(
      "div",
      { staticClass: "tw-w-full tw-absolute tw-px-6 md:tw-px-12 tw-pt-6" },
      [
        _c("p", { staticClass: "tw-text-warning" }, [_vm._v("Check Identity")]),
        _c(
          "div",
          {
            staticClass:
              "tw-flex tw-flex-col md:tw-grid md:tw-grid-cols-2 tw-gap-10",
          },
          [
            _c("div", [
              _c("p", [_vm._v("ID")]),
              _c(
                "div",
                { staticClass: "photo-holder tw-mb-3" },
                [
                  _vm.isIdentificationPDF
                    ? _c("img", {
                        attrs: {
                          width: "150",
                          src: require("@/assets/images/pdf-icon.png"),
                          alt: "PDF Icon",
                        },
                      })
                    : _c("zoom-on-hover", {
                        style: `transform: rotate(${_vm.rotationID}deg);`,
                        attrs: {
                          width: "250px",
                          "img-normal":
                            _vm.patientVerification.identificationURL,
                          scale: _vm.scale,
                          rotation: _vm.rotationID,
                          disabled: _vm.disabled,
                        },
                      }),
                ],
                1
              ),
              _vm.patientVerification.identificationURL
                ? _c("div", [
                    _vm.isIdentificationPDF
                      ? _c(
                          "div",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  squared: "",
                                  variant: "peach",
                                  href: _vm.patientVerification
                                    .identificationURL,
                                  target: "_blank",
                                },
                              },
                              [
                                _c("b-icon", { attrs: { icon: "file-text" } }),
                                _vm._v(" Open PDF "),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c("ul", { staticClass: "list-unstyled tw-w-full" }, [
                          _c(
                            "li",
                            { staticClass: "tw-inline-flex" },
                            [
                              _c(
                                "b-button",
                                {
                                  class: [
                                    !_vm.disabled ? "active" : "",
                                    "mr-2",
                                  ],
                                  attrs: { squared: "", variant: "peach" },
                                  on: {
                                    click: function ($event) {
                                      _vm.disabled = !_vm.disabled
                                    },
                                  },
                                },
                                [_c("b-icon", { attrs: { icon: "search" } })],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            { staticClass: "tw-inline-flex" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { squared: "", variant: "peach" },
                                  on: { click: _vm.rotateId },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "arrow-clockwise" },
                                  }),
                                  _vm._v(" Rotate 90 degrees "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                  ])
                : _vm._e(),
            ]),
            _c("div", [
              _c("p", [_vm._v("Photo")]),
              _c(
                "div",
                { staticClass: "photo-holder tw-mb-3" },
                [
                  _vm.isPhotoPDF
                    ? _c("img", {
                        attrs: {
                          width: "150",
                          src: require("@/assets/images/pdf-icon.png"),
                          alt: "PDF Icon",
                        },
                      })
                    : _c("zoom-on-hover", {
                        style: `transform: rotate(${_vm.rotationPhoto}deg);`,
                        attrs: {
                          width: "180px",
                          "img-normal": _vm.patientVerification.photoURL,
                          scale: _vm.scale,
                          rotation: _vm.rotationPhoto,
                          disabled: _vm.disabled,
                        },
                      }),
                ],
                1
              ),
              _vm.patientVerification.photoURL
                ? _c("div", [
                    _vm.isPhotoPDF
                      ? _c(
                          "div",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  squared: "",
                                  variant: "peach",
                                  href: _vm.patientVerification.photoURL,
                                  target: "_blank",
                                },
                              },
                              [
                                _c("b-icon", { attrs: { icon: "file-text" } }),
                                _vm._v(" Open PDF "),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c("ul", { staticClass: "list-unstyled tw-w-full" }, [
                          _c(
                            "li",
                            { staticClass: "tw-inline-flex" },
                            [
                              _c(
                                "b-button",
                                {
                                  class: [
                                    !_vm.disabled ? "active" : "",
                                    "mr-2",
                                  ],
                                  attrs: { squared: "", variant: "peach" },
                                  on: {
                                    click: function ($event) {
                                      _vm.disabled = !_vm.disabled
                                    },
                                  },
                                },
                                [_c("b-icon", { attrs: { icon: "search" } })],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            { staticClass: "tw-inline-flex" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { squared: "", variant: "peach" },
                                  on: { click: _vm.rotatePhoto },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "arrow-clockwise" },
                                  }),
                                  _vm._v(" Rotate 90 degrees "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "tw-col-span-2 tw--mt-5 tw-flex tw-flex-col md:tw-grid md:tw-grid-cols-2 tw-gap-10 tw-p-6 verify-information",
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "b-alert",
                      {
                        attrs: {
                          show: _vm.alert.show,
                          variant: _vm.alert.type,
                        },
                      },
                      [_vm._v(_vm._s(_vm.alert.message))]
                    ),
                    _c("p", { staticClass: "tw-text-base tw-mb-5" }, [
                      _vm._v("Name and DOB"),
                    ]),
                    _c("h4", { staticClass: "bold" }, [
                      _vm._v(
                        " You may make small changes to match the name on the ID: "
                      ),
                    ]),
                    _c("p", { staticClass: "text-muted tw-mb-2" }, [
                      _vm._v(
                        " - e.g. change ‘Lily’ to ‘Lilienne’ (nickname vs real name) "
                      ),
                    ]),
                    _c("p", { staticClass: "text-muted tw-mb-2" }, [
                      _vm._v(
                        " - or ‘Khristina’ to ‘Khristine’ (spelling error) "
                      ),
                    ]),
                    _c("p", { staticClass: "text-muted mb-3 tw-mb-2" }, [
                      _vm._v(
                        " - e.g. ‘Robert’ to ‘Andrew’ (first name vs middle name) "
                      ),
                    ]),
                  ],
                  1
                ),
                _c("div", [
                  _c("p", { staticClass: "mb-4" }, [
                    _vm._v(
                      " If the name or DOB provided are entirely different than on the ID, please select the issue. "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "tw-grid tw-grid-cols-2 tw-gap-6" },
                    [
                      _c(
                        "div",
                        { attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "bold",
                              attrs: { for: "firstName" },
                            },
                            [_vm._v("First Name:")]
                          ),
                          _c("b-form-input", {
                            staticClass: "pl-0",
                            attrs: {
                              id: "firstName",
                              type: "text",
                              readonly: "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.patientInfo.firstname,
                              callback: function ($$v) {
                                _vm.$set(_vm.patientInfo, "firstname", $$v)
                              },
                              expression: "patientInfo.firstname",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            { staticClass: "bold", attrs: { for: "lastName" } },
                            [_vm._v("Last Name:")]
                          ),
                          _c("b-form-input", {
                            staticClass: "pl-0",
                            attrs: {
                              id: "lastName",
                              type: "text",
                              readonly: "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.patientInfo.lastname,
                              callback: function ($$v) {
                                _vm.$set(_vm.patientInfo, "lastname", $$v)
                              },
                              expression: "patientInfo.lastname",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tw-col-span-2",
                          attrs: { role: "group" },
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "bold", attrs: { for: "birthday" } },
                            [_vm._v("Date of Birth:")]
                          ),
                          _c("b-form-datepicker", {
                            attrs: {
                              id: "birthday",
                              readonly: "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.patientInfo.birthday,
                              callback: function ($$v) {
                                _vm.$set(_vm.patientInfo, "birthday", $$v)
                              },
                              expression: "patientInfo.birthday",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "tw-p-5 tw-text-right md:tw-sticky tw-bottom-0 action-btn tw-space-x-3 tw-mt-6",
          },
          [
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "b-modal",
                    rawName: "v-b-modal.issue",
                    modifiers: { issue: true },
                  },
                ],
                staticClass: "tw-mb-4 md:tw-mb-0",
                attrs: { pill: "", size: "lg", variant: "outline-primary" },
              },
              [_vm._v(" No, There's an issue ")]
            ),
            _c(
              "b-button",
              {
                staticClass: "tw-mb-4 md:tw-mb-0",
                attrs: { size: "lg", pill: "", variant: "primary" },
                on: { click: _vm.nextStep },
              },
              [_vm._v(" " + _vm._s(_vm.nextText) + " ")]
            ),
          ],
          1
        ),
        _c(
          "b-modal",
          {
            attrs: {
              id: "issue",
              "hide-header": "",
              "hide-header-close": "",
              centered: "",
              "hide-footer": "",
              size: "lg",
              "footer-class": "issue",
            },
          },
          [
            _c(
              "b-row",
              { staticClass: "py-5" },
              [
                _c("b-col", { attrs: { cols: "12", md: "4" } }, [
                  _c("h2", { staticClass: "heading tw-text-3xl" }, [
                    _vm._v("Identity"),
                    _c("br"),
                    _vm._v("Issue"),
                  ]),
                ]),
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: "7" } },
                  [
                    _c(
                      "b-alert",
                      {
                        attrs: {
                          show: _vm.alert.show,
                          variant: _vm.alert.type,
                        },
                      },
                      [_vm._v(_vm._s(_vm.alert.message))]
                    ),
                    _vm.patientInfo
                      ? _c(
                          "p",
                          {
                            staticClass: "mb-4 mr-3",
                            staticStyle: { "font-size": "16px" },
                          },
                          [
                            _vm._v(" for "),
                            _c(
                              "span",
                              {
                                staticClass: "mb-0",
                                staticStyle: {
                                  "font-size": "22px",
                                  "font-weight": "500",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      `${_vm.patientInfo.firstname} ${_vm.patientInfo.lastname}`
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("b-form-select", {
                      staticClass: "mb-3",
                      attrs: { options: _vm.failedreasonoptions },
                      model: {
                        value: _vm.failedreason,
                        callback: function ($$v) {
                          _vm.failedreason = $$v
                        },
                        expression: "failedreason",
                      },
                    }),
                    _c("b-form-textarea", {
                      staticClass: "p-0 !tw-text-[#19191c]",
                      attrs: {
                        placeholder:
                          _vm.failedreason === "Other"
                            ? `Notes`
                            : `Notes (optional)`,
                        rows: "6",
                        "no-resize": "",
                      },
                      model: {
                        value: _vm.notes,
                        callback: function ($$v) {
                          _vm.notes = typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "notes",
                      },
                    }),
                    _c(
                      "b-row",
                      {
                        staticClass: "text-center mt-5",
                        attrs: { "align-h": "center" },
                      },
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  pill: "",
                                  block: "",
                                  variant: "outline-primary",
                                  disabled: _vm.processing,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.$bvModal.hide("issue")
                                  },
                                },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  pill: "",
                                  block: "",
                                  variant: "primary",
                                  disabled: _vm.processing,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.onIdentityIssue.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.processing ? "Please wait" : "Submit"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }