<template>
  <!-- :style="[width ? { width: width } : '', height ? { height: height } : '']" -->
  <div
    class="zoom-on-hover"
    :class="{ zoomed }"
    :style="{ width: width, height: height }"
    @touchstart="touchzoom"
    @mousemove="move"
    @mouseenter="zoom"
    @mouseleave="unzoom"
  >
    <img class="zoom" ref="zoom" :src="imgNormal" />
    <v-lazy-image class="normal" ref="normal" :src="imgNormal" />

    <!-- <img class="normal" ref="normal" :src="imgNormal" />
    <img class="zoom" ref="zoom" :src="imgZoom || imgNormal" /> -->
    <!-- <v-lazy-image class="normal" ref="normal" :src="imgNormal" />
    <v-lazy-image class="zoom" ref="zoom" :src="imgZoom || imgNormal" /> -->
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image/v2";
export default {
  components: {
    VLazyImage,
  },
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    imgNormal: {
      type: String,
    },
    imgZoom: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    scale: {
      type: [String, Number],
      default: 0,
    },
    rotation: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      scaleFactor: 1,
      resizeCheckInterval: null,
      zoomed: false,
      holder: "",
    };
  },
  methods: {
    pageOffset(el) {
      // -> {x: number, y: number}
      // get the left and top offset of a dom block element
      let rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return {
        y: rect.top + scrollTop,
        x: rect.left + scrollLeft,
      };
    },
    touchzoom(event) {
      if (this.disabled) return;
      this.move(event);
      this.zoomed = !this.zoomed;
    },
    zoom() {
      if (!this.disabled) this.zoomed = true;
      // this.move(event);
    },
    unzoom() {
      if (!this.disabled) this.zoomed = false;
      // this.move(event);
    },
    move(event) {
      if (this.disabled || !this.zoomed) return;
      let offset = this.pageOffset(this.$el),
        zoom = this.$refs.zoom,
        normal = this.$refs.normal.$el,
        relativeX =
          this.rotation === 90 || this.rotation === 270
            ? event.clientY - offset.y + window.pageYOffset
            : event.clientX - offset.x + window.pageXOffset,
        relativeY =
          this.rotation === 90 || this.rotation === 270
            ? event.clientX - offset.x + window.pageXOffset
            : event.clientY - offset.y + window.pageYOffset,
        normalFactorX = relativeX / normal.offsetWidth,
        normalFactorY = relativeY / normal.offsetHeight,
        x =
          normalFactorX *
          (zoom.offsetWidth * this.scaleFactor - normal.offsetWidth),
        y =
          normalFactorY *
          (zoom.offsetHeight * this.scaleFactor - normal.offsetHeight);

      zoom.style.left = -x + "px";
      zoom.style.top = -y + "px";
    },
  },
  mounted() {
    if (this.$props.scale) {
      this.scaleFactor = this.$props.scale;
      this.$refs.zoom.style.transform = "scale(" + this.scaleFactor + ")";
    }
  },
  updated() {},
  beforeDestroy() {},
};
</script>

<style scoped>
.zoom-on-hover {
  position: relative;
  overflow: hidden;
}
.zoom-on-hover .normal {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.zoom-on-hover .zoom {
  position: absolute;
  opacity: 0;
  transform-origin: top left;
  z-index: 9;
}
.zoom-on-hover.zoomed .zoom {
  opacity: 1;
}
.zoom-on-hover.zoomed .normal {
  opacity: 0;
}

.v-lazy-image {
  opacity: 0;
  transition: opacity 1s;
}
.v-lazy-image-loaded {
  opacity: 1;
}
</style>
