var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "zoom-on-hover",
      class: { zoomed: _vm.zoomed },
      style: { width: _vm.width, height: _vm.height },
      on: {
        touchstart: _vm.touchzoom,
        mousemove: _vm.move,
        mouseenter: _vm.zoom,
        mouseleave: _vm.unzoom,
      },
    },
    [
      _c("img", {
        ref: "zoom",
        staticClass: "zoom",
        attrs: { src: _vm.imgNormal },
      }),
      _c("v-lazy-image", {
        ref: "normal",
        staticClass: "normal",
        attrs: { src: _vm.imgNormal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }