<template>
  <PatientInfoLayout>
    <div class="tw-w-full tw-absolute tw-px-6 md:tw-px-12 tw-pt-6">
      <p class="tw-text-warning">Check Identity</p>
      <div class="tw-flex tw-flex-col md:tw-grid md:tw-grid-cols-2 tw-gap-10">
        <div>
          <p>ID</p>
          <div class="photo-holder tw-mb-3">
            <!-- <b-skeleton-img
              v-if="!patientVerification.identificationURL"
              height="150px"
              width="250px"
            ></b-skeleton-img> -->
            <img
              v-if="isIdentificationPDF"
              width="150"
              src="@/assets/images/pdf-icon.png"
              alt="PDF Icon"
            />
            <zoom-on-hover
              v-else
              width="250px"
              :style="`transform: rotate(${rotationID}deg);`"
              :img-normal="patientVerification.identificationURL"
              :scale="scale"
              :rotation="rotationID"
              :disabled="disabled"
            ></zoom-on-hover>
            <!-- <template v-if="patientVerification.identificationURL">
              
            </template> -->
            <!-- <template v-else>
              <img src="@/assets/images/loader.gif" alt="" width="250" />
            </template> -->
          </div>
          <div v-if="patientVerification.identificationURL">
            <div v-if="isIdentificationPDF">
              <b-button
                squared
                variant="peach"
                :href="patientVerification.identificationURL"
                target="_blank"
              >
                <b-icon icon="file-text"></b-icon>
                Open PDF
              </b-button>
            </div>
            <ul class="list-unstyled tw-w-full" v-else>
              <li class="tw-inline-flex">
                <b-button
                  squared
                  variant="peach"
                  @click="disabled = !disabled"
                  :class="[!disabled ? 'active' : '', 'mr-2']"
                >
                  <b-icon icon="search"></b-icon>
                </b-button>
              </li>
              <li class="tw-inline-flex">
                <b-button squared variant="peach" @click="rotateId">
                  <b-icon icon="arrow-clockwise"></b-icon>
                  Rotate 90 degrees
                </b-button>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <p>Photo</p>
          <div class="photo-holder tw-mb-3">
            <!-- <b-skeleton-img
              v-if="!patientVerification.photoURL"
              height="150px"
              width="250px"
            ></b-skeleton-img> -->
            <!-- patientVerification.photoURL -->
            <img
              v-if="isPhotoPDF"
              width="150"
              src="@/assets/images/pdf-icon.png"
              alt="PDF Icon"
            />
            <zoom-on-hover
              v-else
              width="180px"
              :style="`transform: rotate(${rotationPhoto}deg);`"
              :img-normal="patientVerification.photoURL"
              :scale="scale"
              :rotation="rotationPhoto"
              :disabled="disabled"
            ></zoom-on-hover>
          </div>
          <div v-if="patientVerification.photoURL">
            <div v-if="isPhotoPDF">
              <b-button
                squared
                variant="peach"
                :href="patientVerification.photoURL"
                target="_blank"
              >
                <b-icon icon="file-text"></b-icon>
                Open PDF
              </b-button>
            </div>

            <ul class="list-unstyled tw-w-full" v-else>
              <li class="tw-inline-flex">
                <b-button
                  squared
                  variant="peach"
                  @click="disabled = !disabled"
                  :class="[!disabled ? 'active' : '', 'mr-2']"
                >
                  <b-icon icon="search"></b-icon>
                </b-button>
              </li>
              <li class="tw-inline-flex">
                <b-button squared variant="peach" @click="rotatePhoto">
                  <b-icon icon="arrow-clockwise"></b-icon>
                  Rotate 90 degrees
                </b-button>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="
            tw-col-span-2 tw--mt-5 tw-flex tw-flex-col
            md:tw-grid md:tw-grid-cols-2
            tw-gap-10 tw-p-6
            verify-information
          "
        >
          <div>
            <b-alert :show="alert.show" :variant="alert.type">{{
              alert.message
            }}</b-alert>
            <p class="tw-text-base tw-mb-5">Name and DOB</p>

            <h4 class="bold">
              You may make small changes to match the name on the ID:
            </h4>
            <p class="text-muted tw-mb-2">
              - e.g. change ‘Lily’ to ‘Lilienne’ (nickname vs real name)
            </p>
            <p class="text-muted tw-mb-2">
              - or ‘Khristina’ to ‘Khristine’ (spelling error)
            </p>
            <p class="text-muted mb-3 tw-mb-2">
              - e.g. ‘Robert’ to ‘Andrew’ (first name vs middle name)
            </p>
          </div>
          <div>
            <p class="mb-4">
              If the name or DOB provided are entirely different than on the ID,
              please select the issue.
            </p>
            <div class="tw-grid tw-grid-cols-2 tw-gap-6">
              <div role="group">
                <label for="firstName" class="bold">First Name:</label>

                <b-form-input
                  id="firstName"
                  class="pl-0"
                  type="text"
                  readonly
                  disabled
                  v-model="patientInfo.firstname"
                ></b-form-input>
              </div>

              <div role="group">
                <label for="lastName" class="bold">Last Name:</label>

                <b-form-input
                  id="lastName"
                  class="pl-0"
                  type="text"
                  readonly
                  disabled
                  v-model="patientInfo.lastname"
                ></b-form-input>
              </div>

              <div class="tw-col-span-2" role="group">
                <label for="birthday" class="bold">Date of Birth:</label>
                <b-form-datepicker
                  id="birthday"
                  readonly
                  disabled
                  v-model="patientInfo.birthday"
                ></b-form-datepicker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          tw-p-5 tw-text-right
          md:tw-sticky
          tw-bottom-0
          action-btn
          tw-space-x-3 tw-mt-6
        "
      >
        <b-button
          pill
          size="lg"
          v-b-modal.issue
          variant="outline-primary"
          class="tw-mb-4 md:tw-mb-0"
        >
          No, There's an issue
        </b-button>
        <b-button
          size="lg"
          pill
          variant="primary"
          @click="nextStep"
          class="tw-mb-4 md:tw-mb-0"
        >
          {{ nextText }}
        </b-button>
      </div>

      <b-modal
        id="issue"
        hide-header
        hide-header-close
        centered
        hide-footer
        size="lg"
        footer-class="issue"
      >
        <b-row class="py-5">
          <b-col cols="12" md="4">
            <h2 class="heading tw-text-3xl">Identity<br />Issue</h2>
          </b-col>
          <b-col cols="12" md="7">
            <b-alert :show="alert.show" :variant="alert.type">{{
              alert.message
            }}</b-alert>
            <p class="mb-4 mr-3" style="font-size: 16px" v-if="patientInfo">
              for
              <span class="mb-0" style="font-size: 22px; font-weight: 500">
                {{ `${patientInfo.firstname} ${patientInfo.lastname}` }}
              </span>
            </p>
            <b-form-select
              v-model="failedreason"
              :options="failedreasonoptions"
              class="mb-3"
            ></b-form-select>
            <b-form-textarea
              :placeholder="
                failedreason === 'Other' ? `Notes` : `Notes (optional)`
              "
              v-model.trim="notes"
              rows="6"
              no-resize
              class="p-0 !tw-text-[#19191c]"
            >
            </b-form-textarea>
            <b-row class="text-center mt-5" align-h="center">
              <b-col cols="6">
                <b-button
                  pill
                  block
                  variant="outline-primary"
                  :disabled="processing"
                  @click.prevent="$bvModal.hide('issue')"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col cols="6">
                <b-button
                  pill
                  block
                  variant="primary"
                  :disabled="processing"
                  @click.prevent="onIdentityIssue"
                >
                  {{ processing ? "Please wait" : "Submit" }}
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </PatientInfoLayout>
</template>

<script>
import { PatientService } from "@/services/patient.service.js";
import PatientInfoLayout from "@/layouts/PatientInfoLayout.vue";

import { mapGetters } from "vuex";

import zoomOnHover from "@/components/patients/ZoomOnHover.vue";
export default {
  name: "verification",
  components: {
    zoomOnHover,
    PatientInfoLayout,
  },
  data() {
    return {
      alert: {
        show: false,
        type: "info",
        message: "",
      },
      rotationID: 0,
      rotationPhoto: 0,
      disabled: true,
      scale: 1.5,
      patientId: 0,
      firstName: "",
      lastName: "",
      identificationURL: "",
      processing: false,
      photoURL: "",
      birthday: "",
      config: {
        altInput: true,
        altFormat: "m-d-Y",
        allowInput: true,
        minDate: this.moment().subtract(100, "year").format("YYYY-MM-DD"),
        maxDate: this.moment().subtract(18, "year").format("YYYY-MM-DD"),
      },
      failedreason: "",
      failedreasonoptions: [
        "No ID Uploaded",
        "No Selfie Uploaded",
        "2 Selfies Uploaded",
        "2 IDs Uploaded",
        "Name Incorrect",
        "DOB Incorrect",
        "Picture of ID is too blurry",
        "Selfie is too blurry",
        "Other",
      ],
      notes: "",
    };
  },
  watch: {
    failedreason() {
      this.alert = {
        show: false,
        type: "info",
        message: "",
      };
    },
  },
  methods: {
    alertMessage(type, message) {
      this.alert = {
        type: type,
        message: message,
        show: true,
      };
    },
    rotateId() {
      if (this.rotationID === 270) {
        this.rotationID = 0;
      } else {
        this.rotationID += 90;
      }
    },
    rotatePhoto() {
      if (this.rotationPhoto === 270) {
        this.rotationPhoto = 0;
      } else {
        this.rotationPhoto += 90;
      }
    },
    async nextStep() {
      try {
        this.processing = true;
        this.alert.show = false;
        await PatientService.verifyPatient(this.patientId, {
          verificationstatus: "OK",
        });
        this.$router.push(`/patients/verified/${this.patientId}`);
      } catch (error) {
        this.alertMessage("warning", error.message);
      } finally {
        this.processing = false;
      }
    },
    async onIdentityIssue() {
      try {
        if (this.failedreason === "Other" && !this.notes) {
          this.alertMessage("warning", "Notes is required.");
          return;
        }
        this.processing = true;
        this.alert.show = false;
        await PatientService.verifyPatient(this.patientId, {
          verificationstatus: "FAILED",
          failedreason:
            this.failedreason === "Other"
              ? `Other - ${this.notes}`
              : this.failedreason,
          ...(this.notes && { notes: this.notes }),
        });

        this.$router.push("/");
        // Trigger hide handler
        this.$nextTick(() => {
          this.$bvModal.hide("issue");
        });
      } catch (error) {
        this.alertMessage("warning", error.message);
      } finally {
        this.processing = false;
      }
    },
  },
  computed: {
    nextText() {
      return "Yes, It's a match";
    },
    ...mapGetters({
      patientInfo: "patients/getPatientInfo",
      patientVerification: "patients/getPatientVerification",
    }),
    isIdentificationPDF() {
      return (
        this.patientVerification.identificationFileType === "application/pdf"
      );
    },
    isPhotoPDF() {
      return this.patientVerification.photoFileType === "application/pdf";
    },
  },
  async mounted() {
    window.analytics.page();
    this.$store.commit("patients/CLEAR_PATIENT_INFO");
    this.$store.commit("patients/CLEAR_VERIFICATION");
    this.patientId = this.$route.params.id;
    await this.$store.dispatch(
      "patients/getPatientVerification",
      this.patientId
    );
    await this.$store.dispatch("patients/getPatientInfoV2", {
      patientId: this.patientId,
    });
  },
};
</script>

<style scoped>
/deep/#birthday__value_ {
  color: #ff7a37 !important;
}

/deep/#birthday__outer_ {
  background: transparent !important;
}
</style>

<style lang="scss" scoped>
.verify-information {
  background-color: #ffecca;
}

.darkmode .verify-information {
  background-color: #191c1c;
}

.verified-patient {
  background-color: #ffecca;
}

.photo-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 220px;
  border: 1px solid rgba($primary, 0.25);
  background-color: $white;
  overflow: hidden;
  position: relative;
}

.darkmode .photo-holder {
  background-color: transparent;
  border-color: #bdbdbd;
}

@media (max-width: 768px) {
  .photo-holder {
    width: 100%;
    height: 270px;
  }
}

.form-control {
  color: $secondary;
}

.header-nav,
.action-btn {
  background-color: rgba(#fff, 0.85);
}

.darkmode .header-nav,
.darkmode .action-btn {
  background-color: rgba(#121212, 0.85);
}
</style>
